body {
    font-family: Roboto, sans-serif;
    font-family: Roboto, sans-serif;
    color: #4c4c4c;
    padding: 0;
    margin: 0;
    font-size: 1rem;
}

body #tabs {
    background: #0763ab;
    color: #fff;
}

.about p {
    font-size: 17px;
    font-family: Roboto Slab;
}

body #tabs ul {
    list-style: none;
    display: flex;
    margin: 0;
    position: relative;
    min-height: 5vh;
}

body #tabs ul.submenu {
    flex-direction: column;
    position: absolute;
    background: #0763ab;
    padding: 0;
    margin: 0;
    margin-left: -1.25rem;
    /* height: 100%!important; */
}

body #tabs ul li .submenu {
    display: none;
}

body #tabs ul li:hover .submenu {
    display: flex;

}

body #tabs ul li:hover {
    background-color: #054b83;
}

body #tabs ul li {
    line-height: 3rem;
    padding: 0 1.25rem;
    cursor: pointer;
}

body #tabs ul li a {
    text-decoration: none;
    color: #fff;
}

header {
    position:absolute;
    top: 0;
    z-index: 100;
    background: white;
    /* background: #f7f7f7; */
    width: 100%;
}

main {
    margin-top: 20.35rem;
}
.springer{
    height: 5rem!important;
    width: 100%;
}
.actetLogo{
    height: 8rem!important;
}
.site-header {
    text-align: center;
    width: calc(100% - 22.25rem);
    height: auto;
}

.top-header {
    height: 45.723861vh;
    display: flex;
    align-items: center;
    text-align: center;
}

/* .top-header>div {
    padding: 0 1.25rem;
} */

.top-header img {
    height: 6rem;
}
/* .logo1{
    height:9em!important;
    width:9em;
}
.skitLogo{
    height:9em!important;
    width:9em;
} */

.logo-top-line {
    font-size: 1.7rem;
    font-weight: 900;
}

.logo-top-line2 {
    font-size: 1.3rem;
    line-height: 1.75rem;
    font-weight: 900;
}

.logo-top-line3 {
    font-size: 2rem;
    line-height: 0.75rem;
    font-weight: 900;
    padding-top: 0.5rem;
}

.intro {
    height: 53.61930294906166vh;
    color: #fff;
    background-size: 60%;
    font-size: 3.125rem;
    font-weight: 600;
    line-height: 1.3;
    padding-top: 8.125rem;
}

.conf-button {
    display: block;
    position: relative;
    line-height: 1.625rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
    z-index: 1;
    width: 8.138020833333334vw;
    top: -1.875rem;
}

.conf-button:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    content: '';
    background-color: #4867c0;
    background-image: linear-gradient(to right, #4867c0, #329fec, #4867c0);
    background: #4867c0;
    background: linear-gradient(to right, #4867c0, #329fec, #4867c0);
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.conf-button a {
    display: block;
    position: relative;
    line-height: 3.25;
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
    z-index: 1;
    width: 13.020833333333334vw;
    text-align: center;
}
.url {
    color: #812767;
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: none;
    font-family: "Ubuntu", sans-serif;
    cursor: pointer;
}
.url:hover {
    color: rgb(72, 71, 71);
  }

.about {
    font-family: "Barlow", sans-serif;
    font-size: 1.25rem;
    padding-top: 30px;
}

.about-content {
    font-size: 1.25rem;
    letter-spacing: 0.031rem;
    line-height: 1.8rem;
    padding-bottom: 2.5rem;
}
.link-marquee{
    text-decoration: none;
    font-size:1.1rem;
    text-transform: uppercase;
}
select {
    border: none;
    border-bottom: solid 0.125rem #ccc;
    font-size: 0.938rem;
}

.error-label select {
    border-bottom-color: #F44335;
}

.error-label label,
.error-label p {
    color: #F44335;
}

.error-label p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 0.188rem;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.fs15rem {
    font-size: 1.5rem;
}

.slick-slide img {
    width: 100%;
    max-height: 90vh;
}

.slick-next {
    right: 1.25rem !important;
}

.slick-prev {
    left: 1.25rem !important;
    z-index: 1;
}

.slick-next,
.slick-prev {
    height: 3.125rem !important;
    width: 3.125rem !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 3.125rem !important;
}

.slick-slide>div {
    min-height: calc((100vh / 100vw) * 120);
}

.slide-image {
    position: absolute;
    min-height: 300px;
    width: 100vw;
    top: 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.slide-text {
    position: absolute;
    top: 0;
    font-size: 400%;
    font-weight: bold;
    font-family: Roboto;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.slick-dots li button:before {
    font-size: 0.75rem !important;
}

.about {
    padding: 3.3rem 1.25rem 1.25rem 1.25rem;
    text-align: justify;
}

.about h2 {
    font-weight: 700;
    font-size: 110%;
    line-height: 8.04289544235925vh;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    background: #0763ab;
    color: #FFF;
    padding: 0.25rem 1.375rem;
    line-height: normal;
    font-family:"oswald";
}

.slick-dots {
    bottom: 0.625rem !important;
}

.slick-dots li.slick-active button:before {
    color: white !important;
}

.remaining-time {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.remaining-time li {
    width: 6.510416666666667vw;
    margin-right: 2.5rem;
    border: solid 0.063rem #ccc;
    height: 13.404825737265416vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #07406e;
    color: #fff;
}

.remaining-time li span {
    font-size: 1.875rem;
}

footer {
    background-color: #0763ab;
    padding: 1.5rem;
    color: #fff;
}

footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
}
.mobile{
    display: none;
}

footer .container div {
    padding: 0.25rem;
}

footer .container a {
    color: #fff;
    text-decoration: none;
}

/* .skitLogo {
    height: 5.5rem;
} */

.div1 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 0.25rem;
}

.div2 {
    font-size: 1.3rem;
    font-weight: 900;
    margin-top: 0.25rem;
}

@media screen and (min-width: 1025px) {
    .full {
        display: flex;
        flex-direction: row;
    }
    /* .logo1{
        height:9em!important;
        width:9em;
    } */
    /* .skitLogo{
        height:9em!important;
        width:9em;
    } */
}

.aboutUsimg1 {
    width: 95%;
    margin-left: 1.25rem;
    margin-top: 1.25rem;
    height: 90%;
}

.btn {
    background-color: #0763ab;
    border: none;
    color: white;
    padding: 0.75rem 1.875rem;
    cursor: pointer;
    font-size: 1rem;
}

/* Darker background on mouse-over */
.btn:hover {
    background-color: #054b83;
}

section .fa-times {
    /* transform: rotate(180deg);
    color: var(--violet);
    font-size: 2rem;
    margin-left: 4%; */
    display: none;
}

section .fa-bars {
    display: none;
}

@media (min-width:440px) and (max-width:768px) {
    html {
        font-size: 70%;
    }

    .aboutUsimg1 {
        width: 100%;
        margin-left: 0rem;
        margin-top: 3.25rem;
    }

    .about p {
        font-size: 70%;
        font-family:"Roboto Slab";
    }

    section .fa-bars {
        display: block;
        font-size: 2rem;
    }

    section .navbar {
        position: fixed;
        top: -100rem;
        left: 0;
        width: 100%;
        background: #fff;
        opacity: 0;
        /* transition: .2s linear; */
    }

    section .nav-toggle ul {
        width: 100%;
        flex-flow: column;
        padding: 2rem 0;
    }

    section .nav-toggle ul li {
        margin: 1rem 0;
        width: 100%;
    }

    section .nav-toggle ul li a {
        /* font-size: 10%; */
        display: block;
    }

    section .nav-toggle {
        top: 5.5rem;
        opacity: 1;
        width: auto;
        /* text-align: center; */
    }

    .about h2 {
        font-weight: 700;
        font-size: 61%;
        line-height: 8.04289544235925vh;
        margin-bottom: 1.25rem;
        text-transform: uppercase;
        background: #0763ab;
        color: #FFF;
        padding: 0.25rem 0rem;
        padding-left: 1.375rem;
        text-align: left;
        line-height: normal;
        font-family:"oswald";
    }

    body #tabs {
        width: auto;
    }

    body #tabs ul li {
        /* line-height: 3rem; */
        cursor: pointer;
        padding-right: 0;
        box-sizing: border-box;
    }

    body #tabs ul li .submenu {
        margin-left: 14%;
        margin-top: 0%;
        width: 41%;
        border: 1px solid beige;
        background: white;
        height: 50%;
    }

    body #tabs ul li .submenu a {
        border-bottom: 1px solid #054b83;
        line-height: 5rem;
        color: #054b83;
    }

    .logo-top-line3,
    .div1,
    .div2
    {
        display: none;
    }
    

    .logo-top-line {
        font-size: 3rem;
        font-weight: bold;
    }

    .top-header {
        display: flex;
        align-items: center;
        /* padding-bottom: 1.875rem; */
        width: 105%;
    }
}
.nav-bar{
    width:100%;
}
@media (min-width:281px) and (max-width:440px) {
    html {
        font-size: 70%;
    }
    .springer{
        width: 100%;
    }
    section .fa-times {
        display: block;
        transform: rotate(180deg);
        color: black;
        font-size: 2rem;
        margin-left: 4%;
        border:1px solid #f7f7f7;
    }
    .nav-container{
        width: 100% !important;
        position: fixed!important;
        top: 0!important;
        z-index: 101!important;
        /* bottom: 0; */
        text-align: left;
        /* height: 10%!important; */
    }
.desktop{
    display: none;
}
    main {
        margin-top: 25.3rem;
    }
    .btn {
        background-color: #0763ab;
        border: none;
        color: white;
        padding: 0.75rem 1.875rem;
        cursor: pointer;
        font-size: 1rem;
    }

    footer .container a {
        color: #fff;
        text-decoration: none;
        font-size: 1.2rem;
        padding-left: 0.2rem;
    }

    footer .container div {
        padding: 0.25rem;
    }

    .about .marginTop {
        margin-top: 10%;
    }

    .aboutUsimg1 {
        width: 100%;
        margin-left: 0rem;
        margin-top: 3.25rem;
    }

    .about p {
        font-size: 80%;
        font-family:"Roboto Slab";
    }

    .about {
        padding-top: 1px;
    }

    .about h2 {
        font-weight: 700;
        font-size: 65%;
        line-height: 8.04289544235925vh;
        margin-bottom: 1.25rem;
        text-transform: uppercase;
        background: #0763ab;
        color: #FFF;
        padding: 0.25rem 0rem;
        padding-left: 1.375rem;
        text-align: left;
        line-height: normal;
        font-family:"oswald";
    }

    section .fa-bars {
        display: block;
        font-size: 3rem;
        color: white;
        background-color: #0763ab;
        border: none;
    }

    section .navbar {
        position: fixed;
        top: -100rem;
        left: 0;
        width: 100%;
        background: #fff;
        opacity: 0;
        /* transition: .2s linear; */
    }

    section .nav-toggle ul {
        width: 100%;
        flex-flow: column;
        flex-flow: wrap;
        padding: 2rem 0;
    }

    section .nav-toggle ul li {
        margin: 1rem 0;
        width: 100%;
    }

    section .nav-toggle ul li a {
        font-size: 150%;
        display: block;
    }

    section .nav-toggle {
        top: 5.5rem;
        opacity: 1;
        width: auto;
        /* text-align: center; */
        height: 100vh;
    }

    body #tabs {
        width: auto;
        /* margin-top: 10%;
        margin-right: 40%;
        margin-left: 10%;
        margin-bottom: 10%;
         */
    }

    body #tabs ul li {
        /* line-height: 3rem; */
        cursor: pointer;
        padding-right: 0;
        box-sizing: border-box;
    }

    body #tabs ul li .submenu {
        margin-left: 14%!important;
        margin-top: 0%!important;
        width: 70%!important;
        /* border: 1px solid beige; */
        background: #086bb7cf!important; 
        height: auto;
        /* border: 1px solid whitesmoke; */
        
    }

    body #tabs ul li .submenu a {
        /* border-bottom: 1px solid #054b83; */
        line-height: 4rem!important;
        color: white!important;
        /* border-bottom: 1px solid white; */
    }

    .logo-top-line3,
    .div1,
    .div2{
        display: none;
    }

    .logo-top-line {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .top-header {
        display: flex;
        align-items: center;
        /* padding-bottom: 1.875rem; */
        width: 112%;
        height: 20em!important;
        margin-top: 23px;
        border-bottom:solid 10px #0763ab;
    }
    .skitLogo{
        margin-right: 4rem;
        width:60%;
    }
    .actetLogo{
        margin-right: 4rem;
        width:60%;
    }
    .marquee{
        display: none;
    }
}

@media (min-width:281px) and (max-width:345px) {
    html {
        font-size: 70%;
    }

    .aboutUsimg1 {
        width: 100%;
        margin-left: 0rem;
        margin-top: 3.25rem;
    }

    .about p {
        font-size: 70%;
        font-family:"Roboto Slab";
    }

    .about h2 {
        font-weight: 700;
        font-size: 61%;
        line-height: 8.04289544235925vh;
        margin-bottom: 1.25rem;
        text-transform: uppercase;
        background: #0763ab;
        color: #FFF;
        padding: 0.25rem 0rem;
        padding-left: 1.375rem;
        text-align: left;
        line-height: normal;
        font-family:"oswald";
    }

    section .fa-bars {
        display: block;
        font-size: 2rem;
    }

    section .navbar {
        position: fixed;
        top: -100rem;
        left: 0;
        width: 100%;
        background: #fff;
        opacity: 0;
        /* transition: .2s linear; */
    }

    section .nav-toggle ul {
        width: 100%;
        flex-flow: column;
        padding: 2rem 0;
    }

    section .nav-toggle ul li {
        margin: 1rem 0;
        width: 100%;
    }

    section .nav-toggle ul li a {
        /* font-size: 200%; */
        display: block;
    }

    section .nav-toggle {
        top: 5.5rem;
        opacity: 1;
        width: auto;
        /* text-align: center; */
    }

    body #tabs {
        width: auto;
    }

    body #tabs ul li {
        /* line-height: 3rem; */
        cursor: pointer;
        padding-right: 0;
        box-sizing: border-box;
    }

    body #tabs ul li .submenu {
        margin-left: 14%;
        margin-top: 0%;
        width: 41%;
        border: 1px solid beige;
        background: white;
        height: 50%;
    }

    body #tabs ul li .submenu a {
        border-bottom: 1px solid #054b83;
        ;
        line-height: 5rem;
        color: #054b83;
    }

    .logo-top-line3,
    .div1,
    .div2{
        display: none;
    }

    .logo-top-line {
        font-size: 1rem;
        font-weight: bold;
    }

    .top-header {
        display: flex;
        align-items: center;
        /* padding-bottom: 1.875rem; */
        width: 112%;
    }
}


@media (max-width : 280px) {
    html {
        font-size: 70%;
    }

    .aboutUsimg1 {
        width: 100%;
        margin-left: 0rem;
        margin-top: 3.25rem;
    }

    .about p {
        font-size: 70%;
        font-family:"Roboto Slab";
    }

    .about h2 {
        font-weight: 700;
        font-size: 61%;
        line-height: 8.04289544235925vh;
        margin-bottom: 1.25rem;
        text-transform: uppercase;
        background: #0763ab;
        color: #FFF;
        padding: 0.25rem 0rem;
        padding-left: 1.375rem;
        text-align: left;
        line-height: normal;
        font-family:"oswald";
    }

    section .fa-bars {
        display: block;
        font-size: 2rem;
    }

    section .navbar {
        position: fixed;
        top: -100rem;
        left: 0;
        width: 100%;
        background: #fff;
        opacity: 0;
        /* transition: .2s linear; */
    }

    section .nav-toggle ul {
        width: 100%;
        flex-flow: column;
        padding: 2rem 0;
    }

    section .nav-toggle ul li {
        margin: 1rem 0;
        width: 100%;
    }

    section .nav-toggle ul li a {
        /* font-size: 200%; */
        display: block;
    }

    section .nav-toggle {
        top: 5.5rem;
        opacity: 1;
        width: auto;
        /* text-align: center; */
    }

    body #tabs {
        width: auto;
    }

    body #tabs ul li {
        /* line-height: 3rem; */
        cursor: pointer;
        padding-right: 0;
        box-sizing: border-box;
    }

    body #tabs ul li .submenu {
        margin-left: 14%;
        margin-top: 0%;
        width: 41%;
        border: 1px solid beige;
        background: white;
        height: 50%;
    }

    body #tabs ul li .submenu a {
        border-bottom: 1px solid #054b83;
        ;
        line-height: 5rem;
        color: #054b83;
    }

    .logo-top-line3,
    .div1,
    .div2{
        display: none;
    }

    .logo-top-line {
        font-size: 1.18rem;
        font-weight: bold;
    }

    .site-header {
        width: 100%;
    }

    .top-header {
        display: flex;
        align-items: center;
        /* padding-bottom: 1.875rem; */
        width: 103%;
    }
}

@media (min-width:769px) and (max-width:1040px) {
    .logo-top-line {
        font-size: 3rem;
        font-weight: bold;
    }

    .aboutUsimg1 {
        width: 100%;
        margin-left: 0rem;
        margin-top: 3.25rem;
    }

    .about p {
        font-size: 70%;
        font-family:"Roboto Slab";
    }

    .about h2 {
        font-weight: 700;
        font-size: 61%;
        line-height: 8.04289544235925vh;
        margin-bottom: 1.25rem;
        text-transform: uppercase;
        background: #0763ab;
        color: #FFF;
        padding: 0.25rem 0rem;
        padding-left: 1.375rem;
        text-align: left;
        line-height: normal;
        font-family:"oswald";
    }

    .div1 {
        font-size: 1rem;
    }

    .logo-top-line3 {
        font-size: 0.9rem;
    }

    .top-header {
        display: flex;
        align-items: center;
        /* padding-bottom: 1.875rem; */
    }


    html {
        font-size: 70%;
    }

    section .fa-bars {
        display: block;
        font-size: 2rem;
    }

    section .navbar {
        position: fixed;
        top: -100rem;
        left: 0;
        width: 100%;
        background: #fff;
        opacity: 0;
        /* transition: .2s linear; */
    }

    section .nav-toggle ul {
        width: 100%;
        flex-flow: column;
        padding: 2rem 0;
    }

    section .nav-toggle ul li {
        margin: 1rem 0;
        width: 100%;
    }

    section .nav-toggle ul li a {
        /* font-size: 200%; */
        display: block;
    }

    section .nav-toggle {
        top: 5.5rem;
        opacity: 1;
        width: auto;
        /* text-align: center; */
    }

    body #tabs {
        width: auto;
    }

    body #tabs ul li {
        /* line-height: 3rem; */
        cursor: pointer;
        padding-right: 0;
        box-sizing: border-box;
    }

    body #tabs ul li .submenu {
        margin-left: 14%;
        margin-top: 0%;
        width: 41%;
        border: 1px solid beige;
        background: white;
        height: 50%;
    }

    body #tabs ul li .submenu a {
        border-bottom: 1px solid #054b83;
        ;
        line-height: 5rem;
        color: #054b83;
    }

    .logo-top-line3,
    .div1,
    .div2{
        display: none;
    }

}